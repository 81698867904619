.contact__form {
  @include query(tablet-down) {
    @include column(12, 'mobile');
  }
  @include query(tablet-up) {
    order: 2;

    @include column(6);
  }
}
.contact__map {
  @include query(tablet-down) {
    @include column(12, 'mobile');

    margin-top: 70px;
    margin-bottom: 10px;
  }
  @include query(tablet-up) {
    @include column(6);
  }
}
.contact__info {
  margin-top: 25px;

  @include query(mobile) {
    @include column(12, 'mobile');
  }
  @include query(tablet-down) {
    text-align: left;
  }
  @include query(tablet) {
    @include column(6);

  }
  @include query(tablet-up) {
    @include column(4);

    text-align: center;
  }
}
.contact__label {
  font-family: $font-stack-itc;
  font-size: 24px;
  line-height: (32/24);
}
.contact__meta {
  margin: 23px 0;

  color: rgba(color('text-black-light'), 0.5);
  font-size: 18px;
  font-weight: 700;
}
.contact__link {
  color: inherit;
  transition: color 200ms ease;

  &:hover {
    color: color('text-black-light');
  }
}
