.post-content {
  .row {
    & + .row {
      margin-top: rem(nth(map-get($font-sizes, 'post-content'), 2));
    }
  }
  &__block {
    @include font-size('post-content');

    @include query(mobile) {
      @include column(12, 'mobile');
    }
    @include query(mobile-up) {
      @include column(11);
    }

    &--half {
      @include query('tablet-up') {
        @include column(6);
      }
    }

    figure {
      img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }

    > * {
      margin: 0;

      & + * {
        margin: rem(nth(map-get($font-sizes, 'post-content'), 2)) 0 0;
      }
    }

    a {
      transition: opacity 200ms ease;

      &:hover {
        opacity: .5;
      }
    }
  }
}
