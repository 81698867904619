.hero {
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: rem(40) 0;

  .body--homepage & {
    height: 100vh;
  }
  .body--article & {
    height: 80vh;
  }

  @include query('mobile') {
    align-items: flex-end;
  }

  @include query('mobile-up') {
    align-items: center;
  }

  @media screen and (max-height: 400px) {
    height: rem(450);
  }

  &__wrapper {
    @include container();

    position: relative;
    z-index: 1;
  }

  &__holder {
    @include row();
    color: color('white');
  }

  &__title {
    @include font-size('hero-title-mobile');

    font-family: $font-stack-dmSerif;

    @include query('mobile') {
      @include column(12);

    }

    @include query('mobile-up') {
      @include column(8);
    }

    @include query('tablet-up') {
      @include font-size('h1');
    }
  }

  &__subtitle {
    @include font-size('hero-subtitle-mobile');
    margin-top: rem(10);

    @include query('mobile') {
      @include column(12);
    }

    @include query('mobile-up') {
      @include column(8);
    }

    @include query('tablet-up') {
      @include font-size('hero-subtitle-desktop');
    }
  }

  &__link {
    margin-top: rem(45);
    @include column(12);

    .button:hover {
      background-color: rgba(color('white'), 0.9);
    }
  }

  &:after {
    content: '';
    background-image: url('../assets/icons/scroll-icon.svg');
    background-repeat: no-repeat;
    width: rem(62);
    height: rem(62);
    background-size: rem(62) rem(62);
    background-position: center;
    position: absolute;
    bottom: rem(40);
    left: 50%;
    transform: translateX(-50%);
    display: none;

    .body--homepage & {
      display: block;
    }
  }
}
