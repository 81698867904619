.footer {
  margin-top: 50px;
  padding: 70px 0;

  position: relative;

  &:before {
    width: 100%;

    position: absolute;
    top: 0;
    left: 50%;

    border-top: 2px solid color('primary');
    content: "";
    transform: translateX(-50%);

    @include query(desktop-down) {
      max-width: percentage(329/375);
    }
    @include query(desktop) {
      max-width: 1100px;
    }
  }
}
.footer__wrapper {
  @include container();
}
.footer__holder {
  @include row();

  justify-content: center;
}
.footer__navigation {
  margin: 0;
  padding: 0;

  list-style: none;
}
.footer__navigation-item {
  display: inline-flex;
  align-items: center;

  font-family: $font-stack-itc;
  line-height: (24/18);
  font-size: 18px;

  &:before {
    color: color('primary');
    content: "//"
  }
  &:first-child {
    &:before {
      content: none;
    }
  }
}
.footer__link {
  display: block;
  padding: 0.3em;

  color: color('primary');
  text-transform: uppercase;
  transition: color 100ms ease;

  &:hover {
    color: rgba(color('primary'), 0.5);
  }
}

.footer__networks {
  display: flex;
  margin: 15px 0;
  justify-content: center;
}
.footer__network {
  display: inline-block;
  padding: 18px;
  transition: opacity 200ms ease;

  &:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;

    background-position: center center;
    background-repeat: no-repeat;
    content: "";
  }

  &:hover {
    opacity: .5;
  }
}
.footer__network--facebook {
  &:before {
    background-image: get-icon('facebook', color('primary'));
  }
}
.footer__network--instagram {
  &:before {
    background-image: get-icon('instagram', color('primary'));
  }
}
.footer__network--twitter {
  &:before {
    background-image: get-icon('twitter', color('primary'));
  }
}
