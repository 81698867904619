.post-share {
  margin-top: rem(50);
  margin-bottom: rem(85);

  &__text {
    @include font-size('h4');
    @include column(12);
  }

  &__links {
    flex-direction: column;
    margin-top: rem(25);
    @include column(12);
  }

  &__link {
    display: inline-block;
    padding: 11px;
    border: rem(1) solid color('tertiary');
    border-radius: rem(50);
    transition: opacity 200ms ease;

    &:not(:first-child) {
      margin-left: rem(5);
    }

    &:before {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;

      background-position: center center;
      background-repeat: no-repeat;
      content: "";
    }

    &--facebook {
      &:before {
        background-image: get-icon('facebook', color('tertiary'));
      }

      &:hover {
        opacity: 0.5;
      }
    }

    &--instagram {
      &:before {
        background-image: get-icon('instagram', color('tertiary'));
      }

      &:hover {
        opacity: 0.5;
      }
    }

    &--twitter {
      &:before {
        background-image: get-icon('twitter', color('tertiary'));
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
