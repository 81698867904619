/* stylelint-disable */

@mixin font-stack($fonts...) {
  font-family: make-font-stack($fonts...);
}

@mixin query($name) {
  @include mappy-query($name) {
    @content;
  }
}

@mixin font-size($name) {
  $list: map-get($font-sizes, $name);

  $font-size: nth($list, 1);
  $line-height: nth($list, 2);
  $font-weight: nth($list, 3);

  font-size: rem($font-size);
  line-height: rem($line-height);

  @if ($font-weight) {
    font-weight: $font-weight;
  } @else {
    font-weight: normal;
  }
}

/* stylelint-enable */
