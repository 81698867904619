// Assets
$asset-root: '~@/assets/';

// Typography
$browser-font-size: 16px;
$html-font-size: 100%;

$font-primary: 'DM Sans', sans-serif;;
$font-secondary: 'DM Serif Display', serif;
$font-tertiary: 'ITCAvantGardeStd';

$font-stack-dmSans: $font-primary, Arial;
$font-stack-dmSerif: $font-secondary, Georgia;
$font-stack-itc: $font-tertiary, Arial, sans-serif;

$font-sizes: (
  'h1': (80, 100, 400),
  'h2': (60, 75, 400),
  'h3': (48, 60, 400),
  'h4': (24, 32, 700),
  'body-desktop': (16, 24, 400),
  'hero-title-mobile': (30, 45, 400),
  'hero-subtitle-desktop': (20, 30, 400),
  'hero-subtitle-mobile': (14, 30, 400),
  'card-category': (12, 24, 700),
  'buttons': (18, 24, 700),
  'preamble-title-single-mobile': (30, 45, 400),
  'preamble-text-mobile': (20, 32, 400),
  'preamble-text': (18, 32, 400),
  'post-content': (18, 32, 400)
);

// Grid
$grid-queries: ('mobile', 'tablet', 'laptop', 'desktop');
$grid: (
  'default': (
    'container': (
      'max-width': 1170px,
      'padding': 30px,
    ),
    'gutter': 30px,
    'columns': 12,
    'responsive': (
      'mobile': (
        'container': (
          'padding': 23px,
        ),
        'gutter': 9px,
      ),
      //'tablet': (
      //  'container': (
      //    'padding': 45px,
      //  ),
      //),
    ),
  ),
  //'small': (
  //  'container': (
  //    'max-width': 650px,
  //    'padding': 21px,
  //  ),
  //  'gutter': 9px,
  //  'columns': 12,
  //)
);

// Breakpoints
$breakpoints: (
  'small': 600px,
  'medium': 950px,
  'large': 1400px,
);

// Media queries
$mappy-queries: (
  'mobile': mappy-bp(max small), // < 600
  'mobile-up': mappy-bp(small), // > 600
  'tablet': mappy-bp(small medium), // 600 - 950
  'tablet-down': mappy-bp(max medium), // <=950
  'tablet-up': mappy-bp(medium), // > 950
  'laptop': mappy-bp(medium large), // 951 - 1400
  'desktop-down': mappy-bp(max large), // <= 1400
  'desktop': mappy-bp(large), // > 1400
);

// Colors
$color-palette: (
  'pure-white': #fff,
  'pure-black': #000,
  'blue': #0000FF,
  'redish': #FF5858,
  'grey': #9F9FAF,
  'grey-light': #C7C7D4,
  'grey-extralight': #F2F2F9,
  'black-light': #333333,
  'preamble-content': #4A4A4A,
);

$colors: (
  'white': map-get($color-palette, 'pure-white'),
  'black': map-get($color-palette, 'pure-black'),
  'primary': map-get($color-palette, 'blue'),
  'secondary': map-get($color-palette, 'redish'),
  'tertiary': map-get($color-palette, 'grey'),
  'ter-light': map-get($color-palette, 'grey-light'),
  'ter-extralight': map-get($color-palette, 'grey-extralight'),
  'text-black-light': map_get($color-palette, 'black-light'),
  'text-preamble': map_get($color-palette, 'preamble-content'),
);

// Z-indexes
$z-indexes: (
  'content': 1000,
);

$icons: (
  'facebook': '<svg viewBox="0 0 12 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M3,8 L0,8 L0,12 L3,12 L3,24 L8,24 L8,12 L11.642,12 L12,8 L8,8 L8,6.333 C8,5.378 8.192,5 9.115,5 L12,5 L12,0 L8.192,0 C4.596,0 3,1.583 3,4.615 L3,8 Z" id="facebook" fill="%%COLOR%%"></path></svg>',
  'instagram': '<svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M12,2.163 C15.204,2.163 15.584,2.175 16.85,2.233 C20.102,2.381 21.621,3.924 21.769,7.152 C21.827,8.417 21.838,8.797 21.838,12.001 C21.838,15.206 21.826,15.585 21.769,16.85 C21.62,20.075 20.105,21.621 16.85,21.769 C15.584,21.827 15.206,21.839 12,21.839 C8.796,21.839 8.416,21.827 7.151,21.769 C3.891,21.62 2.38,20.07 2.232,16.849 C2.174,15.584 2.162,15.205 2.162,12 C2.162,8.796 2.175,8.417 2.232,7.151 C2.381,3.924 3.896,2.38 7.151,2.232 C8.417,2.175 8.796,2.163 12,2.163 L12,2.163 Z M12,0 C8.741,0 8.333,0.014 7.053,0.072 C2.695,0.272 0.273,2.69 0.073,7.052 C0.014,8.333 0,8.741 0,12 C0,15.259 0.014,15.668 0.072,16.948 C0.272,21.306 2.69,23.728 7.052,23.928 C8.333,23.986 8.741,24 12,24 C15.259,24 15.668,23.986 16.948,23.928 C21.302,23.728 23.73,21.31 23.927,16.948 C23.986,15.668 24,15.259 24,12 C24,8.741 23.986,8.333 23.928,7.053 C23.732,2.699 21.311,0.273 16.949,0.073 C15.668,0.014 15.259,0 12,0 Z M12,5.838 C8.597,5.838 5.838,8.597 5.838,12 C5.838,15.403 8.597,18.163 12,18.163 C15.403,18.163 18.162,15.404 18.162,12 C18.162,8.597 15.403,5.838 12,5.838 Z M12,16 C9.791,16 8,14.21 8,12 C8,9.791 9.791,8 12,8 C14.209,8 16,9.791 16,12 C16,14.21 14.209,16 12,16 Z M18.406,4.155 C17.61,4.155 16.965,4.8 16.965,5.595 C16.965,6.39 17.61,7.035 18.406,7.035 C19.201,7.035 19.845,6.39 19.845,5.595 C19.845,4.8 19.201,4.155 18.406,4.155 Z" id="instagram" fill="%%COLOR%%" fill-rule="nonzero"></path></svg>',
  'twitter': '<svg viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M24,2.36771944 C23.117,2.76968827 22.168,3.04040197 21.172,3.16242822 C22.189,2.53794094 22.97,1.54840033 23.337,0.369155045 C22.386,0.947497949 21.332,1.36792453 20.21,1.59454471 C19.313,0.613207547 18.032,0 16.616,0 C13.437,0 11.101,3.0414274 11.819,6.19872847 C7.728,5.98851518 4.1,3.97867104 1.671,0.923913043 C0.381,3.19319114 1.002,6.16181296 3.194,7.66509434 C2.388,7.63843314 1.628,7.41181296 0.965,7.03342904 C0.911,9.37243642 2.546,11.5607055 4.914,12.0477851 C4.221,12.240566 3.462,12.285685 2.69,12.1339212 C3.316,14.1396637 5.134,15.5988515 7.29,15.6398687 C5.22,17.3041427 2.612,18.04758 0,17.7317473 C2.179,19.164274 4.768,20 7.548,20 C16.69,20 21.855,12.0826497 21.543,4.98154225 C22.505,4.26886792 23.34,3.37981952 24,2.36771944 Z" id="twitter" fill="%%COLOR%%"></path></svg>'
);
