.preamble {
  $this: &;

  &--single {
    #{$this}__wrapper {
      margin-top: rem(30);
      margin-bottom: rem(30);

      @include query('mobile-up') {
        margin-top: rem(50);
        margin-bottom: rem(50);
      }
    }

    #{$this}__block {
      align-items: flex-start;

      @include column(12);

      @include query('mobile-up') {
        @include column(8);
      }
    }

    #{$this}__title {
      @include font-size('preamble-title-single-mobile');

      @include query('mobile-up') {
        @include font-size('h1');
        margin-top: rem(15);
      }
    }
  }

  &--homepage {
    #{$this}__block {
      align-items: center;
      text-align: center;

      @include column(12);

      @include query('mobile-up') {
        @include offset(3);
        @include column(6);
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__wrapper {
    margin-top: rem(60);
    margin-bottom: rem(50);

    @include query('mobile-up') {
      margin-top: rem(117);
      margin-bottom: rem(90);
    }
  }

  &__title {
    @include font-size('h2');
    color: color('primary');
    font-family: $font-stack-dmSerif;
  }

  &__content {
    @include font-size('preamble-text-mobile');
    color: color('text-preamble');
    margin-top: rem(15);

    @include query('mobile-up') {
      @include font-size('preamble-text');
    }
  }
}

.preamble--noHero {
  padding-top: 90px;
}
