html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}
body {
  @include font-stack($font-primary);
  @include font-size('body-desktop');

  position: relative;

  color: color('pure-white');
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
a {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
}

.container {
  @include container();
}

.row {
  @include row();
}
