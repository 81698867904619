.form__heading {
  font-size: rem(nth(map_get($font-sizes, 'h2'), 1));
  font-weight: 400;
  line-height: 1;

  color: color('primary');
  font-family: $font-stack-dmSerif;
}
.form__group {
  padding: 16px 0 23px;
}
.form__group--actions {
  display: flex;
  align-items: flex-start;
}
.form__feedback {
  margin: 17px 0 0 20px;
  padding: 0;

  list-style: none;
}
.form__feedback--errors {
  color: color('secondary');
}
.form__feedback--success {
  color: #269100;
}
.form__floater {
  margin-top: 12px;

  position: relative;

  font-size: 14px;
  line-height: 30px;
}
.form__field {
  display: block;
  width: 100%;

  border: none;
  border-bottom: 1px solid color('primary');
  color: color('primary');
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  outline: none;

  &::placeholder {
    color: transparent;
  }

  .hasError & {
    border-color: color('secondary');
  }
}
.form__field--area {
  height: 145px;
  padding: 12px 24px;

  border: 1px solid color('primary');
  resize: none;

  &::placeholder {
    color: rgba(color('primary'), 0.5);
  }
}
.form__label {
  display: block;

  position: absolute;
  top: 0;
  left: 0;

  color: rgba(color('primary'), 0.5);
  font-size: inherit;
  line-height: inherit;
  transition: 0.2s ease-in;
  transition-property: top, font-size;

  &[for] {
    cursor: pointer;
  }

  .form__field:focus + &,
  .form__field:not(:placeholder-shown) + &,
  .form__field:valid + & {
    top: -20px;

    font-size: 10px;
  }
  .hasError & {
    color: color('secondary');
  }
}
