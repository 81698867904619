.card-list {
  $this: &;

  &__wrapper {
    @include container();
  }

  &__holder {
    @include row();

    @include query('mobile') {
      margin-top: rem(30);
    }
    @include query('tablet') {
      margin-top: rem(50);
    }
    @include query('tablet-up') {
      margin-top: rem(75);
    }
  }

  &__item {
    display: flex;

    @include query('mobile') {
      @include column(12, 'mobile');

      margin-bottom: rem(30);
    }

    @include query('tablet') {
      @include column(6);

      margin-bottom: rem(50);
    }

    @include query('tablet-up') {
      @include column(4);

      margin-bottom: rem(75);
    }


    &--featured-primary {
      @include query('mobile') {
        @include column(12, 'mobile');
      }
      @include query('tablet') {
        @include column(12);
      }
      @include query('tablet-up') {
        @include column(8);
      }
    }

    &--featured-secondary {
      @include query(mobile) {
        @include column(12, 'mobile');
      }
      @include query(mobile-up) {
        @include column(12);
      }
    }
  }

  &__filter-wrapper {
    @include query(tablet-down) {
      position: relative;

      border-bottom: 1px solid color('primary');

      &.hasShadow {
        &:before,
        &:after {
          width: 65px;
          height: 100%;

          position: absolute;
          top: 0;
          z-index: 15;

          content: "";
          pointer-events: none;
          transition: opacity 0.2s ease-in;
        }
        &:before {
          left: 0;
          background-image: linear-gradient(to right, color('white') 43%, rgba(255,255,255,0.00) 100%);
        }
        &:after {
          left: auto;
          right: 0;

          background-image: linear-gradient(to right, rgba(255,255,255,0.00) 0%, color('white') 57%);
        }

        &.atStart {
          &:before {
            opacity: 0;
          }
        }
        &.atEnd {
          &:after {
            opacity: 0;
          }
        }
      }
    }
    @include query(tablet-up) {
      @include container();
    }
  }
  &__filter-holder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include query(tablet-down) {
      max-width: 100%;

      overflow-x: auto;
    }
  }

  &__load-more {
    text-align: center;
  }

  &__filter-btn {
    width: 100%;
    text-transform: uppercase;

    position: relative;

    @include query(tablet-down) {
      flex: 1 0 125px;
    }
    @include query(tablet-up) {
      max-width: rem(234);
      min-width: rem(125);
    }

    &:hover {
      #{$this}__filter-element:not(:checked) {
        + #{$this}__filter-label {
          color: rgba(color('primary'), 0.5);
        }
      }

    }
  }
  &__filter-element {
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;

    &:checked {
      + .card-list__filter-label {
        color: color('white');
        background-color: color('primary');
      }
    }
  }
  &__filter-label {
    display: flex;
    width: 100%;
    height: rem(69);
    padding: 0 rem(10);

    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    background-color: transparent;
    color: color('primary');
    cursor: pointer;
    font-family: $font-stack-itc;
    @include font-size('buttons');

    @include query(tablet-down) {
    }
    @include query(tablet-up) {
      border-right: 1px solid color('primary');

      &:first-child {
        border-left: 1px solid color('primary');
      }
    }
  }
}

.posts-filter-section {
  margin-bottom: rem(80);
}
