.button {
  display: inline-block;
  text-decoration: none;
  border-radius: rem(50);
  transition: background-color 200ms ease, color 200ms ease;
  padding: rem(20) rem(50);
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;

  &--primary {
    background-color: color('primary');
    color: color('white');
    border: rem(1) solid color('primary');

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: color('primary');
    }
  }
}
