.header {
  width: 100%;
  padding-top: 35px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.header__navigation {
  @include query(mobile) {
    display: none;
  }
  @include query(mobile-up) {
    display: flex;
    margin: 0 0 0 auto;
    padding: 0;

    list-style: none;
  }
}
.header__navigation-item {
  display: block;
}
.header__navigation-link {
  display: block;
  padding: 25px;

  color: color('white');
  font-family: $font-stack-itc;
  font-size: 18px;
  text-transform: uppercase;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.7;
  }

  .body--single & {
    color: color('primary');
  }
}
