



































































































































































.fade-in-out-leave-active {
  transition: opacity .5s;
}
.fade-in-out-enter-active {
  transition: opacity .8s;
}
.fade-in-out-enter, .fade-in-out-leave-to {
  opacity: 0;
}
