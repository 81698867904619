/* stylelint-disable */

@function is-number($value) {
  @return type-of($value) == 'number';
}

@function is-percentage($value) {
  @return is-number($value) and unit($value) == '%';
}

@function strip-unit($number) {
  @if is-number($number) and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
    $string
  );
}

@function rem($size, $reverse: false) {
  $base-font-size: ($browser-font-size / 100) * $html-font-size;
  $unitless: strip-unit($size);
  $unitless-base: strip-unit(if(is-percentage($html-font-size), $base-font-size, $html-font-size));

  @if $reverse {
    @if str-index(#{$size}, 'px') {
      @return $size;
    }

    @return $unitless * $unitless-base * 1px;
  }

  @if str-index(#{$size}, 'rem') {
    @return $size;
  }

  @return $unitless / $unitless-base * 1rem;
}

@function px($size, $reverse: false) {
  @return rem($size, if($reverse, false, true));
}

@function color($name, $map: $colors) {
  @return map-get($map, $name);
}

@function svg-url($svg){
  @if not str-index($svg,xmlns) {
    $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
  }

  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);

    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    //  $chunk: str-replace($chunk, '&', '%26');
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return url('data:image/svg+xml,#{$encoded}');
}

@function z-index($name, $list: $z-indexes) {
  //@if index($list, $name) {
  //  @return (length($list) - index($list, $name)) + 1;
  //}
  //
  //@warn 'There is no item "#{$name}" in the z-index list (#{$list}).';
  //@return auto;

  @if map-has-key($z-indexes, $name) {
    @return map-get($z-indexes, $name);
  }

  @warn 'There is no item "#{$name}" in the z-index map.';
  @return auto;
}

@function z($name, $list: $z-indexes) {
  @return z-index($name, $list);
}

@function make-font-stack($fonts...) {
  $system-font-stack: (
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  );

  @return #{join($fonts, $system-font-stack)};
}

@function spacing($multiplier, $base-multiplier: 10) {
  @return rem($multiplier * $base-multiplier);
}

@function asset($path, $root: $asset-root) {
  @return url($root + $path);
}

@function image($path, $root: $asset-root) {
  @return asset('images/' + $path, $root);
}

$data-svg-prefix: 'data:image/svg+xml;utf-8,';
@function get-icon($icon, $color: #000, $alpha: null) {

  //@if 'color' != type-of($color) {
  //
  //  @warn 'The requested color - "' + $color + '" - was not recognized as a Sass color value.';
  //  @return null;
  //}

  @if map-has-key($icons, $icon) {
    $icon: map-get($icons, $icon);
    $placeholder: '%%COLOR%%';
    $data-uri: str-replace(url($data-svg-prefix + $icon), $placeholder, $color);

    @if $alpha {
      @return $data-uri;
    } @else {
      @return str-replace($data-uri, '#', '%23');
    }
  }

  @warn 'The requested icon - "' + $icon + '" - is not defined in the $icons map.';
  @return null;
}
