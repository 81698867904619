/* stylelint-disable */

$DEFAULT_TYPE: 'default';

$KEY_CONTAINER: 'container';
$KEY_MAX_WIDTH: 'max-width';
$KEY_PADDING: 'padding';
$KEY_COLUMNS: 'columns';
$KEY_GUTTER: 'gutter';
$KEY_RESPONSIVE: 'responsive';
$KEY_SIZE: 'size';

@function map-deep-merge($map1, $map2) {
  $merged: $map1;

  @each $key, $value in $map2 {
    @if (not map-has-key($merged, $key)) or (type-of(map-get($merged, $key)) != type-of($value)) or (not (type-of(map-get($merged, $key)) == map and type-of($value) == map)) {
      $merged: map-merge($merged, ($key: $value));
    } @else {
      $merged: map-merge($merged, ($key: map-deep-merge(map-get($merged, $key), $value)));
    }
  }

  @return $merged;
}

@mixin _make-container($max-width, $padding) {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @if $max-width != null {
    max-width: rem($max-width);
  }

  @if $padding != null {
    padding-left: rem($padding);
    padding-right: rem($padding);
  }
}

@mixin _make-row($gutter) {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-$gutter / 2);
  margin-right: rem(-$gutter / 2);
}

@mixin _make-column($size, $columns, $gutter) {
  position: relative;
  width: 100%;
  padding-right: rem($gutter / 2);
  padding-left: rem($gutter / 2);
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin _make-offset($size, $columns) {
  $num: $size / $columns;

  margin-left: if($num == 0, 0, percentage($num));
}

@mixin _apply-settings($settings, $component, $query: null, $other: ()) {
  @if $component == 'container' {
    $max-width: map-get(map-get($settings, $KEY_CONTAINER), $KEY_MAX_WIDTH);
    $padding: map-get(map-get($settings, $KEY_CONTAINER), $KEY_PADDING);

    @if $max-width != null and $padding != null {
      $max-width: $max-width + ($padding * 2);
    }

    @if $query and $query != '' {
      @include query($query) {
        @include _make-container($max-width, $padding);
      }
    } @else {
      @include _make-container($max-width, $padding);
    }
  } @else if $component == 'row' {
    $gutter: map-get($settings, $KEY_GUTTER);

    @if $query and $query != '' {
      @include query($query) {
        @include _make-row($gutter);
      }
    } @else {
      @include _make-row($gutter);
    }
  } @else if $component == 'column' {
    $size: map-get($other, $KEY_SIZE);
    $columns: map-get($settings, $KEY_COLUMNS);
    $gutter: map-get($settings, $KEY_GUTTER);

    @if $query and $query != '' {
      @include query($query) {
        @include _make-column($size, $columns, $gutter);
      }
    } @else {
      @include _make-column($size, $columns, $gutter);
    }
  } @else if $component == 'offset' {
    $size: map-get($other, $KEY_SIZE);
    $columns: map-get($settings, $KEY_COLUMNS);

    @if $query and $query != '' {
      @include query($query) {
        @include _make-offset($size, $columns);
      }
    } @else {
      @include _make-offset($size, $columns);
    }
  }
}

@mixin _grid-component($component, $type, $query, $grid, $other: ()) {
  // Check if type exists in the $grid map.
  @if map-has-key($grid, $type) {
    // Get the container from the grid map.
    $container: map-get($grid, $type);

    // Check if container has 'responsive' key.
    @if map-has-key($container, $KEY_RESPONSIVE) {
      // Get the responsive map.
      $_responsive: map-get($container, $KEY_RESPONSIVE);

      // If media-query provided.
      @if $query {
        @if $query == '' {
          @include _apply-settings($container, $component, '', $other);
        } @else {
          @if map-has-key($_responsive, $query) {
            $settings: map-deep-merge($container, map-get($_responsive, $query));

            @include _apply-settings($settings, $component, '', $other);
          } @else {
            @warn 'Unknown query name "#{$query}"';
          }
        }
      } @else {
        // Iterate all media queries.
        @each $grid-query in $grid-queries {
          $settings: $container;

          // Check if responsive map contains the current query.
          // If it does, merge the settings with global settings.
          @if map-has-key($_responsive, $grid-query) {
            $settings: map-deep-merge($container, map-get($_responsive, $grid-query));
          }

          // Return the media query with the settings.
          @include _apply-settings($settings, $component, $grid-query, $other);
        }
      }
    } @else {
      @include _apply-settings($container, $component, '', $other);
    }
  } @else {
    @warn 'Unknown #{$component} type "#{$type}".';
  }
}

@mixin container($type: $DEFAULT_TYPE, $query: null, $grid: $grid) {
  @include _grid-component('container', $type, $query, $grid);
}

@mixin row($type: $DEFAULT_TYPE, $query: null, $grid: $grid) {
  @include _grid-component('row', $type, $query, $grid);
}

@mixin column($size, $query: '', $type: $DEFAULT_TYPE, $grid: $grid) {
  @include _grid-component('column', $type, $query, $grid, ($KEY_SIZE: $size));
}

@mixin offset($size, $query: '', $type: $DEFAULT_TYPE, $grid: $grid) {
  @include _grid-component('offset', $type, $query, $grid, ($KEY_SIZE: $size));
}

/* stylelint-enable */
