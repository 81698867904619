.branding {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1000;

  pointer-events: none;
  transform: translateX(-50%);

  &:before,
  &:after {
    position: absolute;

    content: "";
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  &:before {
    top: auto;
    left: 0;

    transform: translateX(-50%);
  }
  &:after {
    left: auto;
    right: 0;

    background-image: url(../assets/oval.svg);
  }

  @include query(tablet-down) {
    width: 100%;

    &:before {
      width: 306px;
      height: 306px;

      bottom: 80px;

      background-image: url(../assets/oval.svg);
      background-position: center center;
    }
    &:after {
      width: 306px;
      height: 306px;

      top: calc(100vh + 50px);

      transform: translateX(percentage(2/3));
    }
  }

  @include query(tablet-up) {

    &:before {
      height: 100%;

      background-image: url(../assets/futuro.svg);
    }
    &:after {
      width: 698px;
      height: 698px;

      transform: translateX(50%);
    }

    .body--homepage & {
      &:before {
        width: 692px;

        background-position: 0 calc(100vh - 64px);
      }
      &:after {
        top: calc(100vh - (698px / 2));
      }
    }
    .body--article & {
      &:before {
        width: 330px;

        background-position: 0 calc(100% - 80px);
      }
      &:after {
        top: calc(80vh - (698px / 2));
      }
    }
  }
}
